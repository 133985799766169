<template>
  <v-container fluid>
    <template v-if="items.length > 0">
      <v-row>
        <v-col v-for="(item, index) in items" :key="item.text + index" cols="12" sm="4" md="3">
          <access-card :target.sync="item" v-if="checkUser(item.perm)" @announcements="announcements"></access-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AccessCard from "@/views/user-dashboard/access/AccessCard";
import { mapGetters } from "vuex";
import { authGate } from "@/mixins/authGate";

export default {
  name: "OutputTargetGrid",
  mixins: [authGate],
  components: {
    "access-card": AccessCard
  },
  props: {
  },
  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        text: "Post an Announcement",
        route: "/announcements",
        perm: "client_admin"
      },
      {
        icon: "mdi-web",
        text: "View Web Manuals",
        route: "/manuals?tab=0",
        perm: "all"
      },
      {
        icon: "mdi-file-pdf-box",
        text: "View PDF Manuals",
        route: "/manuals?tab=1",
        perm: "all"
      },
      {
        icon: "mdi-format-list-numbered",
        text: "Version History",
        route: "/versions",
        perm: "client_admin"
      },
      {
        icon: "mdi-account-multiple",
        text: "Manage Users",
        route: "/clientusers",
        perm: "client_admin"
      },

    ]
  }),

  beforeMount() {

    this.items = this.buildMenuItems();
  },
  computed: {
    ...mapGetters({
      user: "authUser"
    })
  },
  watch: {

  },
  methods: {
    buildMenuItems() {
      let menuItems = this.items.concat(this.buildReportMenuItems());
      let data = menuItems.filter(this.checkTheUser);
      return data;
    },
    buildReportMenuItems() {
      let menuItems = [];
      this.$store.getters.userForPortalAccess.client.reports.forEach(report => {
        menuItems.push({
          icon: this.getIcon(report.type),
          text: "View " + report.name,
          route: "/reports/" + this.$store.getters.userForPortalAccess.client.id + "/" + report.id + "/view",
          perm: report.perm
        });
      });

      return menuItems;
    },
    checkTheUser(item) {
      return this.checkUser(item.perm);
    },
    getIcon(type) {
      if (type == "Single") {
        return "mdi-chart-bar";
      } else {
        return "mdi-ticket";
      }
    },

    announcements() {
      this.$emit("announcements");
    }
  }
};
</script>
